<template>
  <div class="dashboard">
    <b-row>
      <b-col>
        <b-card>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <b-spinner
                v-if="isFetching"
                variant="primary"
              />
            </div>
            <b-button-group>
              <b-button
                v-for="period in periods"
                :key="period"
                :variant="period === periodSelected ? 'primary' : 'outline-primary'"
                @click="periodSelected = period"
              >
                {{ $t(`_dashboardPeriod.${period}`) }}
              </b-button>
            </b-button-group>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ $t('dashboardScansTitle') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <bar
              :chart-options="chartOptionsBar"
              :chart-data="chartDataBar1"
              :height="250"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ $t('dashboardTimeTitle') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <bar
              :chart-options="chartOptionsBar"
              :chart-data="chartDataBar2"
              :height="250"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ $t('dashboardPopularTitle') }}</b-card-title>
            <div class="dashboard__card-subtitle">
              {{ $t('dashboardPopularSubtitle') }}
            </div>
          </b-card-header>
          <b-card-body>
            <pie
              :chart-options="chartOptionsPie"
              :chart-data="chartDataPie1"
              :height="250"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ $t('dashboardOrderedTitle') }}</b-card-title>
            <div class="dashboard__card-subtitle">
              {{ $t('dashboardOrderedSubtitle') }}
            </div>
          </b-card-header>
          <b-card-body>
            <pie
              :chart-options="chartOptionsPie"
              :chart-data="chartDataPie2"
              :height="250"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BButtonGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from 'chart.js'
import { Bar, Pie } from 'vue-chartjs/legacy'
import { mapGetters } from 'vuex'
import GET_RESTAURANT_ANALYTICS from '@/gql/query/getRestaurantAnalytics.gql'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButtonGroup,
    BButton,
    BSpinner,
    Bar,
    Pie,
  },
  data() {
    return {
      isFetching: true,
      periodSelected: 'today',
      periods: [
        'today',
        'yesterday',
        'last7days',
        'last30days',
        'last12months',
      ],
      chartOptionsBar: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      chartOptionsPie: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      chartDataBar1: {
        labels: Array(24)
          .fill(0)
          .map((i, iIndex) => `${iIndex.toString().length === 1 ? '0' : ''}${iIndex}:00`),
        datasets: [{ data: Array(24).fill(0), backgroundColor: '#7367F0' }],
      },
      chartDataBar2: {
        labels: Array(24)
          .fill(0)
          .map((i, iIndex) => `${iIndex.toString().length === 1 ? '0' : ''}${iIndex}:00`),
        datasets: [{ data: Array(24).fill(0), backgroundColor: '#5EA3F8' }],
      },
      chartDataPie1: {
        labels: ['NO DATA'],
        datasets: [{ data: [1], backgroundColor: ['#BF5EF8', '#8252D9', '#7367F0', '#526BD9', '#5EA3F8'] }],
      },
      chartDataPie2: {
        labels: ['NO DATA'],
        datasets: [{ data: [1], backgroundColor: ['#BF5EF8', '#8252D9', '#7367F0', '#526BD9', '#5EA3F8'] }],
      },
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      subscription: 'billing/subscription',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
    }),
  },
  watch: {
    periodSelected() {
      this.fetch()
    },
    subscription() {
      this.fetch()
    },
    restaurant() {
      this.fetch()
    },
  },
  created() {
    this.setDefaultChartDataPie('chartDataPie1')
    this.setDefaultChartDataPie('chartDataPie2')

    if (!this.$route.params.isFetchDisabledOnCreated) {
      this.fetch()
    }
  },
  methods: {
    setDefaultChartDataPie(name) {
      this[name].labels = ['NO DATA']
      this[name].datasets = [{ ...this[name].datasets[0], data: [1] }]
    },
    async fetch() {
      if (!this.subscription) {
        return
      }

      if (this.isSubscriptionInactiveOrBasic) {
        this.isFetching = false
        return
      }

      this.isFetching = true

      const response = await this.$apollo.query({
        query: GET_RESTAURANT_ANALYTICS,
        variables: {
          restaurantId: this.restaurant.id,
          interval: this.periodSelected,
        },
      })
      const analytics = response.data.getRestaurantAnalytics

      // eslint-disable-next-line no-undef
      const qrCodeScans = structuredClone(analytics.qrCodeScans)
      this.chartDataBar1.labels = qrCodeScans.map(i => i.timeLabel)
      this.chartDataBar1.datasets[0].data = qrCodeScans.map(i => i.value)

      // eslint-disable-next-line no-undef
      const averageUsageTime = structuredClone(analytics.usageTime)
      this.chartDataBar2.labels = averageUsageTime.map(i => i.timeLabel)
      this.chartDataBar2.datasets[0].data = averageUsageTime.map(i => i.value)

      // eslint-disable-next-line no-undef
      const popularMenuItems = structuredClone(analytics.popularMenuItems)
      if (popularMenuItems.length) {
        this.chartDataPie1.labels = popularMenuItems.map(i => i.label)
        this.chartDataPie1.datasets[0].data = popularMenuItems.map(i => i.count)
      } else {
        this.setDefaultChartDataPie('chartDataPie1')
      }

      // eslint-disable-next-line no-undef
      const bookmarkedMenuItems = structuredClone(analytics.bookmarkedMenuItems)
      if (bookmarkedMenuItems.length) {
        this.chartDataPie2.labels = bookmarkedMenuItems.map(i => i.label)
        this.chartDataPie2.datasets[0].data = bookmarkedMenuItems.map(i => i.count)
      } else {
        this.setDefaultChartDataPie('chartDataPie2')
      }

      this.isFetching = false
    },
  },
}
</script>

<style lang="sass">
  .dashboard
    &__card-subtitle
      width: 100%
      font-size: 10px
      line-height: 12px
      margin-top: 5px
</style>
